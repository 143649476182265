.divForm{
    background-color: rgba(171, 171, 171, 0.7);
    border-radius: 10px;
    padding: 20px;
    width: 320px;
    margin: auto;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.logo{
    width:150px;
    height:75px;
}

.error-login{
    margin: 0;
}