.boton{
    margin-bottom:10px;
}
.tarjeta{
    max-width: 360px;
    min-width: 220px;
}

@media screen and (min-width: 868px){
    .formulario{
        width: 60%;
        margin:auto;
        display: block;
    }
    .formulario-interior{
        width: 70%;
        margin:auto;
        display: block;
    }
}
@media screen and (min-width: 168px) {
    .tarjeta {
        max-width: 360px;
        min-width: 220px;
        
    }
    .date{
        max-width: 360px;
        min-width: 116px;
    }
}