
::-webkit-scrollbar-thumb:hover {
    background: #ee9430;
}
.button-style-ver{
    width: 50px;
    height: 50px;
    transition: width 0.2s,height 0.2s;
}
.button-style-ver:hover{
    width: 55px;
    height: 55px;
    /* background:#ee9430; */
    background: #3a9b06;
}
.button-style-eliminar{
    width: 50px;
    height: 50px;
    transition: width 0.2s,height 0.2s;
}
.button-style-eliminar:hover{
    width: 55px;
    height: 55px;
    background:#ee9430;
}