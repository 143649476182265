*{
    margin: 0;
    padding: 0;
}
.container{
    width: 100%;
    
}
.principal-contenedor{
    padding-top: 100px;
}